import React, { useEffect, useState, useRef, Suspense } from 'react';
import './index.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const LazySignallyComponent = React.lazy(() => import('./Signally/Signally'));
const LazyTresorientComponent = React.lazy(() => import('./Tresorient/Tresorient'));
const LazyShiverComponent = React.lazy(() => import('./Shiver/Shiver'));
const LazyBeeComponent = React.lazy(() => import('./Bee/Bee'));
const LazySafepatchComponent = React.lazy(() => import('./Safepatch/Safepatch'));

gsap.registerPlugin(ScrollTrigger);

export default function Projet() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);


  return (
    <section className='project__section' id='project' ref={containerRef}>
      <p className='section__title'>01. Project I worked</p>
      <div className='divider'></div>
      <div className='container__project'>
        <Suspense fallback={<div>Loading...</div>}>
          <article className='project__article tresorient__left' id='signally project'>
            <LazySignallyComponent />
          </article>
        </Suspense>
        {width < breakpoint && <div className='divider'></div>}
        <Suspense fallback={<div>Loading...</div>}>
          <article className='project__article tresorient__left' id='tresorient project'>
            <LazyTresorientComponent />
          </article>
        </Suspense>
        {width < breakpoint && <div className='divider'></div>}
        <Suspense fallback={<div>Loading...</div>}>
          <article className='project__article shiver__right' id='shiver project'>
            <LazyShiverComponent />
          </article>
        </Suspense>
        {width < breakpoint && <div className='divider'></div>}
        <Suspense fallback={<div>Loading...</div>}>
          <article className='project__article bee__left project__mobile' id='bee project'>
            <LazyBeeComponent />
          </article>
        </Suspense>
        {width < breakpoint && <div className='divider'></div>}
        <Suspense fallback={<div>Loading...</div>}>
          <article className='project__article mystere__right' id='safepatch project'>
            <LazySafepatchComponent />
          </article>
        </Suspense>
      </div>
    </section>
  );
}
